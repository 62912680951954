import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '../../assets/img/left.png';
import _imports_1 from '../../assets/img/right.png';
import _imports_2 from '../../assets/img/eye-icon.png';
const _hoisted_1 = {
  key: 0,
  class: "ide-documentation-view-wrap"
};
const _hoisted_2 = {
  class: "ide-documentation-view-header"
};
const _hoisted_3 = {
  class: "ide-documentation-view-content"
};
const _hoisted_4 = {
  class: "main-meun-border",
  ref: "mainBorderRef"
};
const _hoisted_5 = {
  key: 1,
  class: "open-menu"
};
const _hoisted_6 = {
  class: "main-content"
};
const _hoisted_7 = {
  class: "title-box"
};
const _hoisted_8 = {
  class: "name"
};
const _hoisted_9 = {
  class: "version"
};
const _hoisted_10 = {
  class: "base-info-box",
  id: "BaseInfo"
};
const _hoisted_11 = {
  class: "title-of-border-bottom"
};
const _hoisted_12 = {
  class: "description text"
};
const _hoisted_13 = {
  class: "item-title"
};
const _hoisted_14 = {
  class: "text version-remark"
};
const _hoisted_15 = {
  key: 0,
  class: "content-view-box interfaces"
};
const _hoisted_16 = {
  class: "title-of-border-bottom"
};
const _hoisted_17 = {
  key: 1,
  class: "content-view-box feature"
};
const _hoisted_18 = {
  class: "title-of-border-bottom"
};
const _hoisted_19 = {
  key: 2,
  class: "content-view-box driver-event"
};
const _hoisted_20 = {
  class: "title-of-border-bottom"
};
const _hoisted_21 = {
  key: 3,
  class: "content-view-box irCommands"
};
const _hoisted_22 = {
  class: "title-of-border-bottom"
};
const _hoisted_23 = {
  class: "look-header"
};
const _hoisted_24 = {
  class: "look-content"
};
const _hoisted_25 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CopyDocument = _resolveComponent("CopyDocument");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_FullScreen = _resolveComponent("FullScreen");
  const _component_Close = _resolveComponent("Close");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_menu_list = _resolveComponent("menu-list");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_ViewContent = _resolveComponent("ViewContent");
  return $data.show ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["ide-documentation-view-box", {
      'ide-documentation-view-yes-screen': $data.isScreenView,
      'ide-documentation-view-no-screen': !$data.isScreenView
    }])
  }, [_createElementVNode("div", _hoisted_2, [$data.isScreenView ? (_openBlock(), _createBlock(_component_el_icon, {
    key: 0,
    onClick: _cache[0] || (_cache[0] = $event => $data.isScreenView = false)
  }, {
    default: _withCtx(() => [_createVNode(_component_CopyDocument)]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_icon, {
    key: 1,
    onClick: _cache[1] || (_cache[1] = $event => $data.isScreenView = true)
  }, {
    default: _withCtx(() => [_createVNode(_component_FullScreen)]),
    _: 1
  })), _createVNode(_component_el_icon, {
    onClick: _withModifiers($options.handleClose, ["stop"])
  }, {
    default: _withCtx(() => [_createVNode(_component_Close)]),
    _: 1
  }, 8, ["onClick"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: _normalizeClass(["main-menu", {
      border: $data.open
    }])
  }, [_createElementVNode("div", _hoisted_4, null, 512), $data.open ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _imports_0,
    alt: "",
    class: "circleImg",
    onClick: _cache[2] || (_cache[2] = $event => $data.open = false)
  })) : _createCommentVNode("", true), !$data.open ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
    src: _imports_1,
    alt: "",
    onClick: _cache[3] || (_cache[3] = $event => $data.open = true)
  })])) : _createCommentVNode("", true), $data.open ? (_openBlock(), _createElementBlock("h3", {
    key: 2,
    onClick: _cache[4] || (_cache[4] = $event => $options.scrollIntoView(''))
  }, _toDisplayString(this.$t("message.Directory")), 1)) : _createCommentVNode("", true), $data.showMenu ? (_openBlock(), _createBlock(_component_el_menu, {
    key: 3,
    "default-active": "2",
    onSelect: $options.handleSelect,
    style: _normalizeStyle({
      width: $data.open ? `${$data.width}px` : '0px'
    }),
    class: "main-menu-content-box"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_menu_item, {
      index: "BaseInfo",
      class: "base-info-menu"
    }, {
      title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t("message.BaseInfo")), 1)]),
      _: 1
    }), $data.subList.httpInterfaces && $data.subList.httpInterfaces.length ? (_openBlock(), _createBlock(_component_menu_list, {
      key: 0,
      index: "Interfaces",
      label: _ctx.$t('message.Interfaces'),
      list: $data.subList.httpInterfaces
    }, null, 8, ["label", "list"])) : _createCommentVNode("", true), $data.subList.features && $data.subList.features.length && ($data.ideVersion < 3 ? $data.config.portType !== 'IR' : true) ? (_openBlock(), _createBlock(_component_menu_list, {
      key: 1,
      index: "Features",
      label: _ctx.$t('message.Features'),
      list: $data.subList.features
    }, null, 8, ["label", "list"])) : _createCommentVNode("", true), $data.subList.driverEvents && $data.subList.driverEvents.length && ($data.ideVersion < 3 ? $data.config.portType !== 'IR' : true) ? (_openBlock(), _createBlock(_component_menu_list, {
      key: 2,
      index: "DriverEvent",
      label: _ctx.$t('message.DriverEvent'),
      list: $data.subList.driverEvents
    }, null, 8, ["label", "list"])) : _createCommentVNode("", true), $data.subList.irCommands && $data.subList.irCommands.length && ($data.ideVersion < 3 ? $data.config.portType === 'IR' : false) ? (_openBlock(), _createBlock(_component_menu_list, {
      key: 3,
      index: "IRCommands",
      label: _ctx.$t('message.IRCommands'),
      list: $data.subList.irCommands
    }, null, 8, ["label", "list"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["onSelect", "style"])) : _createCommentVNode("", true)], 2), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString($data.config.name), 1), _createElementVNode("p", _hoisted_9, _toDisplayString($data.config.version), 1), $data.config && $data.config.driverNormalStatus && $data.config.driverNormalStatus === 'WithUI' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _imports_2,
    onClick: _cache[5] || (_cache[5] = _withModifiers((...args) => $options.handleLookUI && $options.handleLookUI(...args), ["stop"]))
  })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.$t("message.BaseInfo")), 1), _createElementVNode("p", _hoisted_12, _toDisplayString($data.config.remark), 1), _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t("message.VersionNote")), 1), _createElementVNode("p", _hoisted_14, _toDisplayString($data.config.versionRemark), 1)]), $data.subList.httpInterfaces && $data.subList.httpInterfaces.length ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("h2", _hoisted_16, _toDisplayString(_ctx.$t("message.Interfaces")), 1), _createVNode(_component_ViewContent, {
    type: "Interfaces",
    list: $data.subList.httpInterfaces
  }, null, 8, ["list"])])) : _createCommentVNode("", true), $data.subList.features && $data.subList.features.length && ($data.ideVersion < 3 ? $data.config.portType !== 'IR' : true) ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createElementVNode("h2", _hoisted_18, _toDisplayString(_ctx.$t("message.Feature")), 1), _createVNode(_component_ViewContent, {
    type: "Feature",
    list: $data.subList.features
  }, null, 8, ["list"])])) : _createCommentVNode("", true), $data.subList.driverEvents && $data.subList.driverEvents.length && ($data.ideVersion < 3 ? $data.config.portType !== 'IR' : true) ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createElementVNode("h2", _hoisted_20, _toDisplayString(_ctx.$t("message.DriverEvent")), 1), _createVNode(_component_ViewContent, {
    type: "DriverEvent",
    list: $data.subList.driverEvents
  }, null, 8, ["list"])])) : _createCommentVNode("", true), $data.subList.irCommands && $data.subList.irCommands.length && ($data.ideVersion < 3 ? $data.config.portType === 'IR' : false) ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createElementVNode("h2", _hoisted_22, _toDisplayString(_ctx.$t("message.IRCommands")), 1), _createVNode(_component_ViewContent, {
    type: "IRCommands",
    list: $data.subList.irCommands
  }, null, 8, ["list"])])) : _createCommentVNode("", true)]), $data.showLookUI ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "look-ui-wrap",
    style: _normalizeStyle({
      background: $data.uiMaskBg
    })
  }, [_createElementVNode("div", {
    class: _normalizeClass(["look-ui-box", {
      'preview-yes-screen': $data.isScreenUI,
      'preview-no-screen': !$data.isScreenUI
    }])
  }, [_createElementVNode("div", _hoisted_23, [$data.isScreenUI ? (_openBlock(), _createBlock(_component_el_icon, {
    key: 0,
    onClick: _cache[6] || (_cache[6] = $event => $data.isScreenUI = false)
  }, {
    default: _withCtx(() => [_createVNode(_component_CopyDocument)]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_icon, {
    key: 1,
    onClick: _cache[7] || (_cache[7] = $event => $data.isScreenUI = true)
  }, {
    default: _withCtx(() => [_createVNode(_component_FullScreen)]),
    _: 1
  })), _createVNode(_component_el_icon, {
    onClick: _cache[8] || (_cache[8] = _withModifiers($event => $data.showLookUI = false, ["stop"]))
  }, {
    default: _withCtx(() => [_createVNode(_component_Close)]),
    _: 1
  })]), _createElementVNode("div", _hoisted_24, [_createElementVNode("iframe", {
    src: $data.iframeSrc,
    style: {
      "height": "100%"
    },
    width: "100%",
    frameborder: "0"
  }, null, 8, _hoisted_25)])], 2)], 4)) : _createCommentVNode("", true)])], 2)])) : _createCommentVNode("", true);
}